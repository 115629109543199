import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/havedshipped'
  },
  {
    path: '/havedshipped',
    name: 'HavedShipped',
    component: () => import('../views/HavedShipped.vue')
  },
  {
    path: '/sendGoods',
    name: 'SendGoods',
    component: () => import('../views/SendGoods.vue')
  },
  {
    path: '/productdetails',
    name: 'ProductDetails',
    component: () => import('../views/ProductDetails.vue')
  },
  {
    path: '/shippingdetails',
    name: 'ShippingDetails',
    component: () => import('../views/ShippingDetails.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
